import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import app from '@/plugins/app';
import handler from '@/errors/handler';
import App from './Login.vue';

Vue.config.productionTip = false;

Vue.use(app);

Vue.config.errorHandler = handler;

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app');
