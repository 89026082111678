<template>
  <v-app>
    <div class="wallpaper">
      <Toolbar />
      <div style="padding-bottom:8vh;"></div>
      <LoginInput />
      <LoginFooter />
    </div>
  </v-app>
</template>

<script>
import LoginInput from "@/components/Login/LoginInput";
import Toolbar from "@/components/Common/Toolbar";
import LoginFooter from "@/components/Login/LoginFooter";

export default {
  name: "Login",
  components: {
    LoginInput,
    Toolbar,
    LoginFooter
  },
  created: function() {
    document.querySelectorAll("body")[0].style.overflow = "hidden";
  }
};
</script>

<style lang="scss">
/* #app {
  font-family: "gothic";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
.wallpaper{
  background: url('../../assets/login-bg.jpg');
  background-size:cover;
  height:100vh;
}
</style>