<template>
  <div>
    <!--Start 入力 field Container-->
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <!--Start text部分-->
        <!-- <v-flex xs10 sm4 md2 offset-md5> -->
        <v-flex xs10 sm4 md3>
          <div style="height:30px;"></div>
          <v-form ref="form" lazy-validation autocomplete="off">
          <v-card min-width="470">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              top
            ></v-progress-linear>
            <v-card-text style="width: 250px !important; margin-left: 7rem;">
            <!--Start ID入力 field-->
            <div  style="text-align: center;">
            <div class="login_label">ユーザーID</div>
              <v-text-field
                tabindex="3"
                outlined
                class="login_input"
                v-model="loginInfo.userName"
                maxlength="128"
                prepend-inner-icon="mdi-account-box"
                @keyup.enter="login"
                autofocus
                style="max-width:250px !important;"
                :disabled="loading"
                autocomplete="off"
                oninput="this.value = this.value.replace(/[:;{}|^[\]'&quot;''\\?,&%]/g, '').replace(/(\..*)/g, '$1');"
              ></v-text-field>
            </div>
            <!--End ID入力 field-->
            <!--Start password入力 field-->
            <div class="login_label">パスワード</div>
            <v-text-field
              tabindex="4"
              outlined
              class="login_input"
              v-model="loginInfo.password"
              :maxlength="inputMax"
              :type="showPassword ? 'text' : 'password'"
              prepend-inner-icon="mdi-lock"
              @keyup.enter="login"
              v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              :disabled="loading"
              style="max-width:250px !important;"
              autocomplete="off"
              oninput="this.value = this.value.replace(/[:;{}|^[\]'&quot;''\\?,&%]/g, '').replace(/(\..*)/g, '$1');"
            ></v-text-field>
            <!--End password入力 field-->
            <!--Start login Button-->
            <div style="text-align:center">
              <v-btn
                depressed
                :disabled="btnDisableCheck"
                ref="loginButton"
                @click="login"
                color="primary"
                tabindex="5"
              >ログイン</v-btn>
            </div>
            <!--End login Button-->

            <v-alert style="min-width: 430px; margin-left: -6.5rem;"
              v-show="message !== null"
              outlined
              type="error"
              class="mt-5"
            >
              {{message}}
            </v-alert>

            <div class="mt-5"></div>
            <v-card min-width="430" color="grey lighten-4" elevation="0" style="min-width: 430px; margin-left: -6.5rem;">
              <div>
                <v-card-title primary>
                  <div class="msg-style">
                    <p style="line-height:1.5em;margin-bottom:0px;">
                      注意１：このページではJavaScriptを使用している部分があります。 お使いのブラウザーがこれらの機能をサポートしていない場合、もしくは設定が「有効」となっていない場合は正常に動作しないことがあります。
                    </p>
                    <br>
                    <p style="line-height:1.5em;margin-bottom:0px;">
                      注意２：このページはInternet Explorerに対応していません。 このページの閲覧にはGoogle Chromeを使用して下さい。
                    </p>
                  </div>
                </v-card-title>
              </div>
            </v-card>

            </v-card-text>
            </v-card>
          </v-form>
        </v-flex>
        <!--End text部分-->
      </v-layout>
    </v-container>
    <!--End 入力 field Container-->

  </div>
</template>
<script>
"use strict";
import Vue from "vue";
import config from "@/config"
import rules from '@/util/validator';
import factory from '@/services/factory';
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
Vue.$cookies.config(config.expirationDate);
const service = factory.get('auth');

export default {
  data: () => ({
    name: "LoginInput",
    rules,
    inputMax: 50,
    loginInfo: {
      userName: "",
      password: ""
    },
    showPassword : false,
    loading: false,
    message: null,
  }),

  computed: {
    /**
     * id,pw入力チェック
     */
    btnDisableCheck() {
      return !this.loginInfo.userName || !this.loginInfo.password || this.loading;
    }
  },
  methods: {
    /**
     * ログイン関数
     */
    async login() {
      if (!this.btnDisableCheck) {
        //this.$cookies.set("userName", this.loginInfo.userName);
        //this.$cookies.set("password", this.loginInfo.password);

        if (this.$refs.form.validate()){
          this.loading = true;
          
          let boolean = this.rules.password(this.loginInfo.password) == true? true:false;
          if(!boolean) {
            this.message ='ログインできませんでした。ユーザーIDとパスワードを確認のうえ、もう一度ご入力ください。';
            this.loading = false;
            return;
          }
          let data;
          try {
            
            const res = await service.login(this.loginInfo);
            data = res.data;
            
          } catch (e) {
            console.log(e);
            this.message ='ログインできませんでした。ユーザーIDとパスワードを確認のうえ、もう一度ご入力ください。';
            this.loading = false;
            return;
          }

          if (data.sts === -1) {
            this.message = `連続でログインに失敗したため、${data.cal}分間操作できません。お待ちください。`;
            this.loading = false;
            return;
          }

          // メニュー情報等の格納
          //localStorage.setItem('userid', data.userid);
          localStorage.setItem('username', data.username);
          localStorage.setItem('usertype', data.usertype);
          localStorage.setItem('role', data.role);
          localStorage.setItem('aggregatorId', data.aggregatorId);
          localStorage.setItem('aggregatorName', data.aggregatorName);
          localStorage.setItem('areaId', data.areaId);
          localStorage.setItem('bgroupId', data.bgroupId);
          localStorage.setItem('workflow', data.workflow);
          localStorage.setItem('site', data.site);
          localStorage.setItem('menuJson', data.menuJson);

          // 画面移動
          window.location.href = data.retUrl;

        }
      }
    }
  },

};

</script>

<style lang="scss" scoped>
.login_label {
//  color: #eaeaea !important;
  margin-bottom: 10px;
  text-align: left;
  font-size: 14px;
}
.msg-style{
  width: 400px; 
  font-size:14px;
//  color:black;
  font-weight:bold;
  text-align: left;
}

</style>
